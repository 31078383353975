// In AddSuspectPage.jsx
import React from "react";
import SuspectComponent from "../components/suspect";
import NavBar from "../components/navbar";

const AddSuspect = () => {
    return (
        <div>
            <NavBar />
            <h1 className="text-2xl font-bold mb-4 text-center">Add Suspect</h1>
            <SuspectComponent isEditable={true} />
        </div>
    );
};

export default AddSuspect;

import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar";
import axios from "axios";
import CONFIG from "../config";

const HallOfFame = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`${CONFIG.BACKEND_URL}/api/getAllSuspects`)
            .then((response) => {
                console.log(response.data);
                setData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div>
            <NavBar />
            <h1 className="text-2xl font-bold mb-4 text-center">
                Hall Of Fame
            </h1>
            <div className="container mx-auto mt-8">
                <table className="min-w-full border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 px-4 py-2">
                                Suspect URL
                            </th>
                            <th className="border border-gray-300 px-4 py-2">
                                Verdicts (From Human to Bot)
                            </th>
                            <th className="border border-gray-300 px-4 py-2">
                                Total Verdicts
                            </th>
                            <th className="border border-gray-300 px-4 py-2">
                                Flagged
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="bg-white">
                                <td className="border border-gray-300 px-4 py-2">
                                    {item.suspect_url}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {item.verdicts}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {item.total_verdicts}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {item.flagged}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HallOfFame;

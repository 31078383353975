import React from "react";
import NavBar from "../components/navbar";

const Home = () => {
    return (
        <div>
            <NavBar />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                <a
                    href="/add-suspect"
                    className="text-xl mb-4 px-4 py-2 bg-blue-500 text-white rounded-md w-64 text-center"
                >
                    Add new suspect
                </a>

                <a
                    href="/hall-of-fame"
                    className="text-xl mb-4 px-4 py-2 bg-blue-500 text-white rounded-md w-64 text-center"
                >
                    Hall of Fame
                </a>

                <a
                    href="/investigate-a-suspect"
                    className="text-xl mb-4 px-4 py-2 bg-blue-500 text-white rounded-md w-64 text-center"
                >
                    Investigate a Suspect
                </a>

                <a
                    href="/about"
                    className="text-xl px-4 py-2 bg-blue-500 text-white rounded-md w-64 text-center"
                >
                    About
                </a>
            </div>
        </div>
    );
};

export default Home;

import React, { useEffect, useState } from "react";
import SuspectComponent from "../components/suspect";
import NavBar from "../components/navbar";
import axios from "axios";
import CONFIG from "../config";

const InvestigateASuspect = () => {
    const [data, setData] = useState("");

    useEffect(() => {
        axios
            .get(`${CONFIG.BACKEND_URL}/api/getNewCase`)
            .then((response) => {
                console.log(response);
                setData(response.data.suspect_url);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div>
            <NavBar />
            <h1 className="text-2xl font-bold mb-4 text-center">
                Investigate Suspect Page
            </h1>
            <SuspectComponent isEditable={false} defaultUrl={data} />
        </div>
    );
};

export default InvestigateASuspect;

import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../config";

const SuspectComponent = ({ isEditable, defaultUrl }) => {
    const [url, setUrl] = useState("");
    const [sliderValue, setSliderValue] = useState("Human");
    const [comments, setComments] = useState("");

    const [postData, setPostData] = useState("");

    useEffect(() => {
        setUrl(defaultUrl);
    }, [defaultUrl]);

    const handlePostRequest = () => {
        // Make a POST request to the Flask backend
        const dataToSend = {
            suspect_url: url,
            verdict: parseInt(sliderValue),
            comment: comments,
        };

        axios
            .post(`${CONFIG.BACKEND_URL}/api/addSuspect`, dataToSend)
            .then((response) => {
                // show a tick, then send back home.
            })
            .catch((error) => {
                console.error("Error making POST request:", error);
            });
    };

    const handleSliderChange = (e) => {
        setSliderValue(e.target.value);
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    return (
        <form className="max-w-lg mx-auto p-6 bg-white rounded shadow-lg">
            <div className="mb-4">
                <label
                    htmlFor="url"
                    className="block text-gray-700 font-bold mb-2"
                >
                    Url:
                </label>
                <input
                    type="text"
                    id="url"
                    name="url"
                    value={url}
                    onChange={handleUrlChange}
                    //readOnly={!isEditable}
                    className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base px-4 py-2"
                />
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-blue-500 hover:text-blue-700 ${
                        url ? "block" : "hidden"
                    }`}
                >
                    Open Link
                </a>
            </div>

            <div className="mb-4">
                <label
                    htmlFor="slider"
                    className="block text-gray-700 font-bold mb-2"
                >
                    Your Verdict:
                </label>
                <input
                    type="range"
                    id="slider"
                    name="slider"
                    min="0"
                    max="4"
                    step="1"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    className="slider-thumb w-full"
                />
                <div className="flex justify-between text-xs">
                    <span>Human</span>
                    <span>Bot</span>
                </div>
                <output
                    htmlFor="slider"
                    className="text-gray-700 font-bold mt-2 text-center"
                >
                    {sliderValue === "0"
                        ? "Human"
                        : sliderValue === "4"
                        ? "Bot"
                        : ""}
                </output>
            </div>

            <div className="mb-4">
                <label
                    htmlFor="comments"
                    className="block text-gray-700 font-bold mb-2"
                >
                    Comments:
                </label>
                <textarea
                    id="comments"
                    name="comments"
                    value={comments}
                    onChange={handleCommentsChange}
                    className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base px-4 py-2"
                />
            </div>

            <button
                type="submit"
                className="bg-indigo-500 text-white font-bold py-2 px-4 rounded hover:bg-indigo-700"
                onClick={handlePostRequest}
            >
                Submit
            </button>
        </form>
    );
};

export default SuspectComponent;

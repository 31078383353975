import React from "react";
import NavBar from "../components/navbar";

const About = () => {
    return (
        <div>
            <NavBar />
            <div class="bg-gray-100 py-16">
                <div class="container mx-auto">
                    <div class="max-w-3xl mx-auto text-center mb-12">
                        <h2 class="text-3xl font-bold mb-4">
                            About GPTInvestigations
                        </h2>
                        <p class="text-gray-700">
                            GPTInvestigations is a detective game where you are
                            given a user profile. You must review the profile
                            and come up with the conclusion on whether the user
                            is a GPT bot or not.
                        </p>
                        <p class="text-gray-700">
                            You can also submit profiles for review.
                        </p>
                    </div>
                    <div class="flex justify-center">
                        <a
                            href="/"
                            class="bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-bold hover:bg-blue-600"
                        >
                            Get Started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AddSuspect from "./pages/addSuspect";
import Home from "./pages/home";
import HallOfFame from "./pages/hallOfFame";
import InvestigateASuspect from "./pages/InvestigateASuspect";
import About from "./pages/about";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/add-suspect" element={<AddSuspect />} />
				<Route path="/hall-of-fame" element={<HallOfFame />} />
				<Route
					path="/investigate-a-suspect"
					element={<InvestigateASuspect />}
				/>
				<Route path="/about" element={<About />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
